export const PRELOAD_IMAGES_COUNT = 2;

export const BUTTON_TITLE_MIN_WIDTH = 432;

export const PREVIEW_FILES_EXTENSIONS = [
  'png',
  'jpg',
  'jpeg',
  'doc',
  'docx',
  'docm',
  'pdf',
  'rtf',
  'odt',
  'ods',
];
